import React from 'react'

export const Quiero = () => {

    const assets = {
        cover: 'https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Quiero%20-%20EME/Eme_Quiero%400.5x.jpg',
    };

    return (
        <>
            <div className="relative container mx-auto px-4">
                <div className="flex flex-wrap items-center p-6 my-6">
                    <div className="w-full md:w-1/2 mt-16 p-3">
                        <h1 className="mb-10 text-2xl sm:text-3xl md:text-5xl font-bold text-white uppercase font-heading">Quiero</h1>
                        <h2 className="mb-10 text-2xl sm:text-3xl md:text-4xl text-white uppercase font-heading">Eme Alfonso & Silvio Rodríguez</h2>
                    </div>
                    <div className="w-full md:w-1/2 p-3">
                        <img className="mt-40 lg:mt-0 h-full" src={assets.cover} alt="EME Alfonso & Silvio Rodríguez - Quiero" />
                    </div>
                </div>
            </div>
        </>
    )
}
