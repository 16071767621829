import React from 'react'
import { Quiero } from './Quiero'
import { AS } from './AS'

export const Web = () => {
    return (
        <>
            <section className="relative bg-black overflow-hidden">
                <div className="relative w-2/3 ml-auto">
                    <div className="absolute top-0 left-0 mt-20 lg:-ml-0 rounded-full h-20 lg:h-96 xl:h-160 w-80 md:w-96 lg:w-160">
                        <h2>FAC Store</h2>
                    </div>
                    <div className="absolute top-1/2 right-0 -mr-64 mt-40 lg:mt-20 rounded-full h-20 lg:h-96 xl:h-160 w-80 md:w-96 lg:w-160">
                        <h2>FAC Store</h2>
                    </div>
                </div>
                <nav className="relative flex px-16 justify-between bg-transparent border-b">
                    <div className="pr-14 py-8 lg:border-r">
                        <a className="inline-block text-xl text-white font-medium font-heading" href="#">
                            <h2>FAC Store</h2>
                        </a>
                    </div>
                </nav>
                <Quiero />
                <AS />
                <div className="relative border-t pb-8 lg:pb-6">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap py-10 -mb-6 lg:-mb-4 -mx-4">
                            <div className="w-1/2 lg:w-1/5 py-6 lg:py-4 px-4">
                            </div>
                            <div className="w-1/2 lg:w-1/5 py-6 lg:py-4 px-4">
                            </div>
                            <div className="w-1/2 lg:w-1/5 py-6 lg:py-4 px-4">
                            </div>
                            <div className="w-1/2 lg:w-1/5 py-6 lg:py-4 px-4">
                            </div>
                            <div className="w-1/2 lg:w-1/5 py-6 lg:py-4 px-4">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
                    <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
                    <nav className="relative flex flex-col py-8 px-10 w-full h-full bg-black border-r overflow-y-auto">
                        <a className="inline-block text-xl text-white font-medium font-heading mb-16 md:mb-32" href="#">
                            <img className="h-8" width="auto" src="boldui-assets/logo/logo-boldui-light.svg" alt="" />
                        </a>
                        <ul className="mb-32">
                            <li className="mb-10">
                                <a className="flex items-center" href="#">
                                    <span className="mr-3 text-lg text-white">About</span>
                                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#FFEC3E"></path>
                                    </svg>
                                </a>
                            </li>
                            <li className="mb-10">
                                <a className="flex items-center" href="#">
                                    <span className="mr-3 text-lg text-white">Company</span>
                                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#FFEC3E"></path>
                                    </svg>
                                </a>
                            </li>
                            <li className="mb-10">
                                <a className="flex items-center" href="#">
                                    <span className="mr-3 text-lg text-white">Services</span>
                                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#FFEC3E"></path>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a className="flex items-center" href="#">
                                    <span className="mr-3 text-lg text-white">Contact</span>
                                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.01 3.48047H0V5.57278H12.01V8.71124L16 4.52663L12.01 0.34201V3.48047Z" fill="#FFEC3E"></path>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                        <a className="flex mb-8 items-center justify-center py-4 px-6 rounded-full bg-yellow-300 hover:bg-yellow-400 transform duration-200" href="#">
                            <svg className="mr-3" width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.01 3.16553H0V5.24886H12.01V8.37386L16 4.20719L12.01 0.0405273V3.16553Z" fill="black"></path>
                            </svg>
                            <span className="text-sm font-medium uppercase tracking-wider">Sign Up</span>
                        </a>
                        <a className="flex mb-10 items-center text-white hover:underline" href="#">
                            <span className="mr-2 text-sm">Log In</span>
                            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.01 3.95383H0V6.04614H12.01V9.1846L16 4.99998L12.01 0.815369V3.95383Z" fill="#FFEC3E"></path>
                            </svg>
                        </a>
                        <p className="text-sm text-gray-500">Todos los derechos reservados © FAC {new Date().getFullYear()}</p>
                    </nav>
                </div>
            </section>
        </>
    )
}
