import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import { Web } from './Web';


const audioLists = [
  {
    name: "Quiero",
    singer: "Eme Alfonso & Silvio Rodríguez",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Quiero%20-%20EME/Eme_Quiero%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Quiero%20-%20EME/EME%20ALFONSO%20%26%20SILVIO%20RODRIGUEZ%20-%20QUIERO.mp3"
  },
  {
    name: "01 - Ibaragó Moyugba (Elegguá)",
    singer: "X Alfonso & Eme Alfonso",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/AS%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/01%20-%20Ibarago%CC%81%20Moyugba%20(Eleggua%CC%81).mp3"
  },
  {
    name: "02 - Aguanileó Oggún (Oggún)",
    singer: "X Alfonso & Eme Alfonso",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/AS%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/02%20-%20Aguanileo%CC%81%20Oggu%CC%81n%20(Oggu%CC%81n).mp3"
  },
  {
    name: "03 - Yakuma Kareré (Oshosi)",
    singer: "X Alfonso & Eme Alfonso",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/AS%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/03%20-%20Yakuma%20Karere%CC%81%20(Oshosi).mp3"
  },
  {
    name: "04 - Asoñaña Agó Maddó (Babalú Ayé)",
    singer: "X Alfonso & Eme Alfonso",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/AS%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/04%20-%20Ason%CC%83an%CC%83a%20Ago%CC%81%20Maddo%CC%81%20(Babalu%CC%81%20Aye%CC%81).mp3"
  },
  {
    name: "05 - Rezo Changó (Changó)",
    singer: "X Alfonso & Eme Alfonso",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/AS%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/05%20-%20Rezo%20Chango%CC%81%20(Chango%CC%81).mp3"
  },
  {
    name: "06 - Changó La Meta (Changó)",
    singer: "X Alfonso & Eme Alfonso",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/AS%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/06%20-%20Chango%CC%81%20La%20Meta%20(Chango%CC%81).mp3"
  },
  {
    name: "07 - Orichaó Babá (Obatalá)",
    singer: "X Alfonso & Eme Alfonso",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/AS%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/07%20-%20Orichao%CC%81%20Baba%CC%81%20(Obatala%CC%81).mp3"
  },
  {
    name: "08 - Oyá Wimiloro (Oyá)",
    singer: "X Alfonso & Eme Alfonso",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/AS%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/08%20-%20Oya%CC%81%20Wimiloro%20(Oya%CC%81).mp3"
  },
  {
    name: "09 - Awoyó Yemayá (Yemayá)",
    singer: "X Alfonso & Eme Alfonso",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/AS%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/09%20-%20Awoyo%CC%81%20Yemaya%CC%81%20(Yemaya%CC%81).mp3"
  },
  {
    name: "10 - Iyamilé Oro (Oshún)",
    singer: "X Alfonso & Eme Alfonso",
    cover:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/AS%400.5x.jpg",
    musicSrc:
      "https://pub-daa319d950754570a36d58fd99f802c7.r2.dev/Ancestos%20Sinfonico/10%20-%20Iyamile%CC%81%20Oro%20(Oshu%CC%81n).mp3"
  },
];

const options = {
  theme: "dark",
  locale: "en_US",
  glassBg: true,
  theme: "auto",
  defaultPosition: {
    bottom: 0,
    center: 0
  },
  showMediaSession: true,
  audioLists: audioLists
};

function App() {
  return (
    <>
      <Web />
      <ReactJkMusicPlayer {...options} />
    </>
  );
}

export default App;
